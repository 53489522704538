.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  margin-bottom: 3%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(20px + 2vmin);
  color: black;
}

.App-form {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: calc(20px + 2vmin);
  color: black;
}

.App-values {
  background-color: white;
  display: flex;
  align-items: flex-end;
  font-size: calc(10px + 2vmin);
  color: black;
  width: 40%;
  justify-content: space-around;
  text-align: right;
}

.App-corrected {
  color: green
}

.App-href {
  background-color: white;
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: black;
}

.App-form-label {
  display: flex;

}

.App-form-input {
  padding: 10px;
  margin:0 10px;
  border-radius:10px;
  border:0;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.3);
  align-self: center;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
